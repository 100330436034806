import {
  Dimmer,
  Image,
  Header,
  List,
  Button,
  Icon,
  Divider,
  Loader,
} from "semantic-ui-react";
import "./style.css";
import html2canvas from "html2canvas";
import { useEffect, useState } from "react";
import { CREATE_DOWNLOAD } from "../graphQueries";
import { useMutation } from "@apollo/client";
import moment from "moment";
const Downloader = (props) => {
  const { campaign, formFields, setDownloader } = props;
  const [rendering, setRendering] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState(
    "Creating Invitations..."
  );
  useEffect(() => {
    handleRender();
  }, []);

  const [createDownload] = useMutation(CREATE_DOWNLOAD, {
    onCompleted: (result) => handleCreateCampaign_Result(result),
    onError: (error) => handleCreateCampaign_Error(error),
  });

  const handleCreateCampaign_Result = (result) => {
    console.log(result);
  };

  const handleCreateCampaign_Error = (error) => {
    console.log(error);
  };

  const handleRender = () => {
    const array = [
      {
        id: "artworkOne",
        width: 1080,
        height: 2221,
        alt: "artworkOne_display",
      },
      {
        id: "artworkTwo",
        width: 1392,
        height: 1392,
        alt: "artworkTwo_display",
      },
      {
        id: "artworkThree",
        width: 1920,
        height: 1305,
        alt: "artworkThree_display",
      },
    ];
    for (const a of array) {
      const element = document.getElementById(a.id);
      if (element) {
        html2canvas(element, {
          width: a.width,
          height: a.height,
          scale: 2,
          logging: true,
          allowTaint: true,
          useCORS: true,
        }).then((canvas) => {
          //   document.getElementById(a.alt).appendChild(canvas);
          const img = document.createElement("img");
          img.style = "width:100%;max-height:500px;";
          img.src = canvas
            .toDataURL("image/jpeg")
            .replace("image/jpeg", "image/octet-stream");
          document.getElementById(a.alt).appendChild(img);
        });
        document.getElementById(a.id).classList.add("hidden");
      }
    }
    setTimeout(() => {
      setRendering(false);
    }, 3000);
  };

  const handleDownload = (id, width, height) => {
    setRendering(true);
    setLoadingMessage("Downloading...");
    const element = document.getElementById(id);
    if (element) {
      element.classList.remove("hidden");
      html2canvas(element, {
        width,
        height,
        scale: 2,
        logging: true,
        allowTaint: true,
        useCORS: true,
      }).then((canvas) => {
        // document.body.appendChild(canvas);
        // canvas.toBlob((blob) => {
        //   saveAs(blob, "somethign", element);
        // });
        const a = document.createElement("a");
        // toDataURL defaults to png, so we need to request a jpeg, then convert for file download.
        a.href = canvas
          .toDataURL("image/jpeg")
          .replace("image/jpeg", "image/octet-stream");
        a.download = `${campaign.name}-${width}x${height}.jpg`;
        a.click();
        element.classList.add("hidden");
        createDownload({
          variables: {
            campaignId: campaign.campaignId,
            type: `${width}x${height}`,
          },
        });
        setTimeout(() => {
          setRendering(false);
          setLoadingMessage("");
        }, 1000);
      });
    }
  };

  //   const saveAs = (blob, fileName, element) => {
  //     const link = document.createElement("a");
  //     link.download = fileName;
  //     link.href = URL.createObjectURL(blob);
  //     link.click();
  //     URL.revokeObjectURL(link.href);
  //     element.classList.add("hidden");
  //   };
  return (
    <Dimmer active className="darkDimmer formDimmer">
      {rendering && (
        <Dimmer active className="fullDimmer formDimmer">
          <Loader size="large">{loadingMessage}</Loader>
        </Dimmer>
      )}
      <div>
        <Icon
          onClick={() => setDownloader(false)}
          className="custom-button"
          size="big"
          inverted
          name="close"
          color="white"
          style={{
            position: "absolute",
            right: "10px",
            top: "10px",
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div id="artworkOne_display"></div>
          <div
            style={{ padding: "20px", textAlign: "left", minWidth: "250px" }}
          >
            <Header as="h1" inverted>
              1080 x 1920
              <Header.Subheader>
                Recommended Use:
                <List bulleted>
                  <List.Item>Paperless Post</List.Item>
                  <List.Item>Evite</List.Item>
                  <List.Item>Email</List.Item>
                  <List.Item>Picmonkey</List.Item>
                  <List.Item>Vista Print</List.Item>
                </List>
              </Header.Subheader>
            </Header>
            <Button
              size="large"
              positive
              content="Download"
              onClick={() => handleDownload("artworkOne", 1080, 2221)}
            />
          </div>
        </div>
        <Divider section inverted />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div id="artworkTwo_display"></div>
          <div
            style={{ padding: "20px", textAlign: "left", minWidth: "250px" }}
          >
            <Header as="h1" inverted>
              1080 x 1080
              <Header.Subheader>
                Recommended Use:
                <List bulleted>
                  <List.Item>Paperless Post</List.Item>
                  <List.Item>Evite</List.Item>
                  <List.Item>Email</List.Item>
                  <List.Item>Picmonkey</List.Item>
                </List>
              </Header.Subheader>
            </Header>
            <Button
              size="large"
              positive
              content="Download"
              onClick={() => handleDownload("artworkTwo", 1392, 1392)}
            />
          </div>
        </div>
        <Divider section inverted />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div id="artworkThree_display"></div>
          <div
            style={{ padding: "20px", textAlign: "left", minWidth: "250px" }}
          >
            <Header as="h1" inverted>
              1920 x 1080
              <Header.Subheader>
                Recommended Use:
                <List bulleted>
                  <List.Item>Paperless Post</List.Item>
                  <List.Item>Evite</List.Item>
                  <List.Item>Facebook Event Cover Photo</List.Item>
                  <List.Item>Picmonkey</List.Item>
                  <List.Item>Vista Print</List.Item>
                </List>
              </Header.Subheader>
            </Header>
            <Button
              size="large"
              positive
              content="Download"
              onClick={() => handleDownload("artworkThree", 1920, 1305)}
            />
          </div>
        </div>

        {/* Below here are the actual size renders */}

        <div>
          <div id="artworkOne">
            <div
              style={{
                width: "1080px",
                height: "144px",
                backgroundColor: campaign.backgroundColor,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div>
                <Header
                  style={{
                    fontSize: "50px",
                    color: campaign.textColor,
                    fontFamily: formFields.eventFont,
                  }}
                >
                  {formFields.eventName || ""}
                  <Header.Subheader
                    style={{ fontSize: "40px", color: campaign.textColor }}
                  >
                    {formFields.eventDate || ""}
                  </Header.Subheader>
                </Header>
                {/* <Header
                  style={{
                    fontSize: "2em",
                    color: campaign.textColor,
                    fontFamily: formFields.eventFont,
                  }}
                >
                  {formFields.eventDate || ""}
                </Header> */}
              </div>
            </div>
            <Image
              crossOrigin="anonymous"
              src={campaign.artworkOneLink}
              style={{ width: "1080px", height: "1920px" }}
            />
            <div
              style={{
                width: "1080px",
                height: "157px",
                backgroundColor: campaign.backgroundColor,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div>
                <Header
                  style={{
                    fontSize: "40px",
                    color: campaign.textColor,
                    fontFamily: formFields.eventFont,
                  }}
                >
                  {formFields.eventTheater || ""}
                  <Header.Subheader
                    style={{ fontSize: "30px", color: campaign.textColor }}
                  >
                    {formFields.eventLocation}
                  </Header.Subheader>
                </Header>
                <Header
                  style={{
                    fontSize: "20px",
                    color: campaign.textColor,
                    fontFamily: formFields.eventFont,
                  }}
                >
                  RSVP {formFields.eventContact} by:{" "}
                  {formFields.eventRsvp || ""}
                </Header>
              </div>
            </div>
          </div>
          <div id="artworkTwo">
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                id="leftColumn"
                style={{
                  width: "156px",
                  backgroundColor: campaign.backgroundColor,
                  height: "1392px",
                }}
              ></div>
              <div id="centerColumn">
                <div
                  style={{
                    width: "1080px",
                    height: "140px",
                    backgroundColor: campaign.backgroundColor,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Header
                    style={{
                      fontSize: "50px",
                      color: campaign.textColor,
                      fontFamily: formFields.eventFont,
                    }}
                  >
                    {formFields.eventName || ""}
                    <Header.Subheader
                      style={{ fontSize: "40px", color: campaign.textColor }}
                    >
                      {formFields.eventDate || ""}
                    </Header.Subheader>
                  </Header>
                </div>
                <Image
                  crossOrigin="anonymous"
                  src={campaign.artworkTwoLink}
                  style={{ width: "1080px", height: "1080px" }}
                />
                <div
                  style={{
                    width: "1080px",
                    height: "172px",
                    backgroundColor: campaign.backgroundColor,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div>
                    <Header
                      style={{
                        fontSize: "40px",
                        color: campaign.textColor,
                        fontFamily: formFields.eventFont,
                      }}
                    >
                      {formFields.eventTheater || ""}
                      <Header.Subheader
                        style={{ fontSize: "30px", color: campaign.textColor }}
                      >
                        {formFields.eventLocation}
                      </Header.Subheader>
                    </Header>
                    <Header
                      style={{
                        fontSize: "20px",
                        color: campaign.textColor,
                        fontFamily: formFields.eventFont,
                      }}
                    >
                      RSVP {formFields.eventContact} by:{" "}
                      {formFields.eventRsvp || ""}
                    </Header>
                  </div>
                </div>
              </div>
              <div
                id="rightColumn"
                style={{
                  width: "156px",
                  height: "1392px",
                  backgroundColor: campaign.backgroundColor,
                }}
              ></div>
            </div>
          </div>
          <div id="artworkThree">
            <Image
              crossOrigin="anonymous"
              src={campaign.artworkThreeLink}
              style={{ width: "1920px", height: "1080px" }}
            />
            <div
              style={{
                width: "1920px",
                height: "225px",
                backgroundColor: campaign.backgroundColor,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ padding: "30px" }}>
                <Header
                  style={{
                    fontSize: "50px",
                    color: campaign.textColor,
                    fontFamily: formFields.eventFont,
                  }}
                >
                  {moment(formFields.eventDate).format("LL") || ""}
                  <Header.Subheader
                    style={{
                      fontSize: "40px",
                      color: campaign.textColor,
                      fontFamily: formFields.eventFont,
                    }}
                  >
                    {moment(formFields.eventDate).format("LT") || ""}
                  </Header.Subheader>
                </Header>
              </div>
              <div style={{ padding: "30px" }}>
                <Header
                  style={{
                    fontSize: "50px",
                    color: campaign.textColor,
                    fontFamily: formFields.eventFont,
                  }}
                >
                  {formFields.eventName || ""}
                </Header>
              </div>
              <div style={{ padding: "30px" }}>
                <Header
                  style={{
                    color: campaign.textColor,
                    fontFamily: formFields.eventFont,
                    fontSize: "30px",
                  }}
                >
                  {formFields.eventTheater || ""}
                  <Header.Subheader
                    style={{ fontSize: "20px", color: campaign.textColor }}
                  >
                    {formFields.eventLocation}
                  </Header.Subheader>
                </Header>
                <Header
                  style={{
                    fontSize: "20px",
                    color: campaign.textColor,
                    fontFamily: formFields.eventFont,
                  }}
                >
                  RSVP {formFields.eventContact} by:{" "}
                  {formFields.eventRsvp || ""}
                </Header>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dimmer>
  );
};

export default Downloader;
